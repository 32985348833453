@font-face {
    font-family: "GT Walsheim Pro Medium";
    src: local("GT Walsheim Pro Medium"),
        url(./assets/fonts/GT-Walsheim-Medium.woff2) format("woff2");
}

body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}

#firstLoad {
    color: #1b4942;
}

/*
  prevents iframe stealing mouse focus while drag and drop
  https://github.com/react-grid-layout/react-draggable/issues/613 
*/
.react-draggable-transparent-selection iframe {
    pointer-events: none;
}

.primaryLightBoxShadow {
    box-shadow: 0 0 0 5px rgba(209, 97, 227, 1);
    -webkit-box-shadow: 0 0 0 5px rgba(209, 97, 227, 1);
    -moz-box-shadow: 0 0 0 5px rgba(209, 97, 227, 1);
    animation: blink 1.2s;
    animation-iteration-count: infinite;
}

@keyframes blink {
    0% {
        box-shadow: 0 0 0 5px transparent;
        -webkit-box-shadow: 0 0 0 5px transparent;
        -moz-box-shadow: 0 0 0 5px transparent;
    }
}
